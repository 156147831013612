@import './colors.scss';

* {
	border: 0;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	vertical-align: baseline;
	font-size: 100%;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
}

body {
	background-color: $accentBackground;
}

html {
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}
