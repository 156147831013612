$primary: hsl(203, 100%, 50%);
$neutral: hsl(211, 12%, 43%);
$supporting: hsl(180, 77%, 47%);
$error: hsl(14, 89%, 55%);
$text: hsl(0, 0%, 100%);
$altText: hsl(0, 0%, 100%);
$bubble: hsl(212, 92%, 43%);
$glass: hsla(200, 23%, 97%, 0.5);
$boxShadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
$background: hsl(0, 0%, 7%);
$accentBackground: hsl(0, 0%, 13%);
$border: hsl(0, 0%, 100%);
$gradient: linear-gradient(125deg, hsl(356, 77%, 61%), hsl(268, 70%, 65%));
$gradientHover: linear-gradient(125deg, hsl(356, 77%, 56%), hsl(268, 70%, 60%));
