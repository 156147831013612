* {
	font-family: 'Roboto', sans-serif;
}

$logo: 'clamp(.9rem, 2vw, 2.25rem)';
$title: 'clamp(1.5rem, 3vw, 4rem)';
$a: 'clamp(.9rem, 2vw, 2rem)';
$p: 'clamp(1.5rem, 1.5vw, 2rem)';
$h1: 'clamp(3rem,2vw,3.75rem)';
$h2: 'clamp(1.5rem, 1.5vw, 2rem)';
$h3: '';
$h4: '';
$h5: '';
