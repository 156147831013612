.contact {
	min-height: 80vh;
	margin: 3rem 0;
	color: $text;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: url("../../assets/Frame.png") no-repeat;
	&__header {
		font-size: 50px;
		line-height: 40px;
		margin-bottom: 57px;
		text-transform: capitalize;
	}
	.form {
		width: 60vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		&__inputs {
			width: 100%;
			input {
				@include input;
			}
			input[type="text"] {
				min-width: 41%;
			}
			input[type="email"] {
				min-width: 55%;
				margin-left: 2rem;
			}
		}
		&__subject {
			@include input;
			margin-top: 2rem;
			width: 100%;
		}
		&__textarea {
			@include input(100%, 210px);
			margin: 2rem 0;
			resize: none;
		}
		&__button {
			@include button;
		}
	}
}
