@mixin input($width: 274px, $height: 84px) {
	background-color: transparent;
	border: 1px solid $border;
	border-radius: 0.5rem;
	color: $text;
	font-size: 1.1rem;
	height: $height;
	line-height: 40px;
	padding: 1.5rem 1.2rem;
	width: $width;
	backdrop-filter: blur(.3rem);
	&::placeholder {
		color: $text;
	}
}
