.aboutMe {
	background: url(../../assets/curve.png) no-repeat bottom center, $background;
	clip-path: ellipse(67% 60% at 50% 40%);
	display: flex;
	height: 90vh;
	justify-content: space-between;
	padding-left: 6rem;
	padding-top: 50px;
	background-size: contain;
	&__main {
		color: $text;
		display: flex;
		flex-direction: column;
	}
	&__name {
		font-size: 50px;
		line-height: 67px;
		font-weight: 700;
	}
	&__title {
		font-weight: 400;
		font-size: 50px;
		line-height: 67px;
	}
	&__description {
		width: 475px;
		font-size: 20px;
		line-height: 40px;
		font-weight: 300;
		margin-top: 33px;
	}
	&__resume {
		@include button(0.5rem, 1rem, flex-start);
		margin-top: 40px;
		font-size: 24px;
	}
	&__links {
		margin-top: 31px;
		display: flex;
		&__github {
			margin-right: 1.25rem;
		}
		&__github,
		&__linkedin {
			align-items: center;
			background-color: #fff;
			border-radius: 50%;
			display: flex;
			height: 60px;
			justify-content: center;
			width: 60px;
			img {
				padding: 1.05rem;
			}
		}
	}
	&__avatar {
		width: 20vw;
		height: 35vh;
		border-radius: 50%;
		box-shadow: 0px 0px 10px 5px hsl(0, 0%, 40%);
		margin-right: 20vw;
		margin-top: 10vh;
	}
}
